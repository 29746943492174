<template>
  <b-toast id="alert" :variant="type" solid>
<!--    <template #toast-title>-->
<!--      <div class="d-flex flex-grow-1 align-items-baseline">-->
<!--        <i v-if="type === 'warning'" class="fa fa-warning" width="12" height="12"/>-->
<!--        <i v-else-if="type === 'danger'" class="fa fa-times-circle" width="12" height="12"/>-->
<!--        <i v-else class="fa fa-thumbs-o-up" width="12" height="12"/>-->
<!--      </div>-->
<!--    </template>-->
    <div>{{ text }}</div>
  </b-toast>
</template>

<script>
  export default {
    name: 'Toast',
    data: () => {
      return {
        type: 'warning',
        text: '',
      }
    },
    created() {
      this.$eventBus.$on('alert', this.showAlert)
    },
    beforeDestroy(){
      this.$eventBus.$off('alert');
    },
    methods: {
      showAlert (eventData) {
        this.type = eventData.type;
        this.text = eventData.text;
        this.$bvToast.show('alert');
      },
    },
  }
</script>
