<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="/img/brand/logo.png" width="130" alt="zoo-baza.com.ua">
        <img class="navbar-brand-minimized" src="/img/brand/logo-48.png" width="30" height="30" alt="CoreUI Logo">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen=true />
      <b-navbar-nav class="d-md-down-none">
       <!-- <b-nav-item class="px-3" to="/dashboard">Главная</b-nav-item>
       <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>
       <b-nav-item class="px-3">Settings</b-nav-item> -->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
       <!-- <b-nav-item class="d-md-down-none">
         <i class="icon-bell"></i>
         <b-badge pill variant="danger">5</b-badge>
       </b-nav-item>
       <b-nav-item class="d-md-down-none">
         <i class="icon-bell"></i>
         <b-badge pill variant="danger">5</b-badge>
       </b-nav-item>
       <b-nav-item class="d-md-down-none">
         <i class="icon-list"></i>
       </b-nav-item> -->
       <!-- <b-nav-item class="d-md-down-none">
         <i class="icon-location-pin"></i>
       </b-nav-item> -->
        <!-- <DefaultHeaderDropdownAccnt/> -->
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" />
      <AsideToggler class="d-lg-none" mobile /> -->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <toast />
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://coreui.io">CoreUI</a>
        <span class="ml-1">&copy; 2018 creativeLabs.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import { nav } from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import Toast from '@/components/Toast'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import i18n from "../plugins/i18n";

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    Toast,
  },
  data () {
    return {
      nav: [],
      dismissCountDown: 0,
      showDismissibleAlert: false,
    }
  },
  async beforeMount() {
      this.nav = await nav();
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
        .filter((route) => !route.meta.hide && (route.name || route.meta.label))
        .map((route) => {
          if (route.meta && route.meta.label) {
            route.meta.label = i18n.t(route.meta.label);
            return route;
          }
          route.name = i18n.t(route.name);
          return route;
        })
    }
  }
}
</script>
