import {APIService} from "./services/api";

export const nav = async () => {

  let tags = [];
  try {
    const result = await APIService.get('tag?filter=isRequired||$eq||true');
    if (result) {
      tags = result.map(row => {
        return {
          name: row.name,
          url: `/ucs/tag-item/list/${row.id}`,
          icon: `fa ${row.iconClass}`,
        }
      });
    }
  } catch (e) {
    console.error(e);
  }

  let storesActions = [];
  try {
    const result = await APIService.get('store?limit=1&filter=status||$eq||1');
    if (result) {
      const store = result[0];
      storesActions = [
        {
          name: 'Склад',
          url: `/store/store-item/list/${store.id}`,
          icon: 'fa fa-barcode'
        },
        {
          name: 'Склад по датам',
          url: `/store/store-history/list/${store.id}`,
          icon: 'fa fa-calendar'
        },
        {
          name: 'История изменений',
          url: `/store/store-change-log/list/${store.id}`,
          icon: 'fa fa-pencil-square-o'
        },
      ];
    }
  } catch (error) {
    console.error(error);
  }

  return [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'primary',
    //     text: 'NEW'
    //   }
    // },
    // {
    //   title: true,
    //   name: 'Theme',
    //   class: '',
    //   wrapper: {
    //     element: '',
    //     attributes: {}
    //   }
    // },
    // {
    //   name: 'Colors',
    //   url: '/theme/colors',
    //   icon: 'icon-drop'
    // },
    // {
    //   name: 'Typography',
    //   url: '/theme/typography',
    //   icon: 'icon-pencil'
    // },
    {
      name: 'Структура',
      url: '/structure',
      icon: 'icon-list',
      children: [
        {
          name: 'Меню',
          url: '/structure/menu',
          icon: 'fa fa-bars',
          children: [
            {
              name: 'Верхнее меню',
              url: '/structure/menu/list/1',
              icon: 'fa fa-bars',
            },
            {
              name: 'Нижнее меню',
              url: '/structure/menu/list/2',
              icon: 'fa fa-bars',
            }
          ]
        },
        {
          name: 'Страницы',
          url: '/structure/page',
          icon: 'fa fa-file'
        },
        {
          name: 'Баннеры',
          url: '/structure/banners/1',
          icon: 'fa fa-image',
          children: [
            {
              name: 'Главная страница',
              url: '/structure/banners/1',
              icon: 'fa fa-image',
            },
            {
              name: 'Баннер слева',
              url: '/structure/banners/2',
              icon: 'fa fa-image',
            },
            {
              name: 'Баннер справа',
              url: '/structure/banners/3',
              icon: 'fa fa-image',
            },
            {
              name: 'Мобильный баннер',
              url: '/structure/banners/4',
              icon: 'fa fa-image',
            }
          ]
        },
      ]
    },
    {
      name: 'Модули',
      url: '/buttons',
      icon: 'fa fa-plus-circle',
      children: [
        {
          name: 'Статьи',
          url: '/module/article',
          icon: 'fa fa-pencil'
        },
        {
          name: 'Новости',
          url: '/module/news',
          icon: 'fa fa-newspaper-o'
        },
      ]
    },
    {
      name: 'SEO',
      url: '/seo',
      icon: 'fa fa-globe',
      children: [
        {
          name: 'Sitemap',
          url: '/seo/sitemap',
          icon: 'fa fa-sitemap'
        },
      ]
    },
    {
      name: 'Email',
      url: '/email',
      icon: 'fa fa-envelope',
      children: [
        {
          name: 'Группы',
          url: '/email/group',
          icon: 'fa fa-folder-open'
        },
        {
          name: 'Подписчики',
          url: '/email/subscriber',
          icon: 'fa fa-at'
        },
        {
          name: 'Рассылка',
          url: '/email/newsletter',
          icon: 'fa fa-envelope'
        },
      ]
    },
    {
      name: 'Редактор структур',
      url: '/ucs',
      icon: 'fa fa-cube',
      children: [
        {
          name: 'Аттрибуты',
          url: '/ucs/tag',
          icon: 'fa fa-cube'
        },
        {
          name: 'Статусы товара',
          url: '/ucs/product-status',
          icon: 'fa fa-cube'
        },
      ]
    },
    {
      name: 'Товары',
      url: '/ucs/product',
      icon: 'fa fa-cubes',
      children: [
        {
          name: 'Товары',
          url: '/ucs/product',
          icon: 'fa fa-cubes'
        },
        ...tags,
      ]
    },
    {
      name: 'Фильтры',
      url: '/filter',
      icon: 'fa fa-sliders',
      children: [
        {
          name: 'Фильтры',
          url: '/filter/name/list',
          icon: 'fa fa-sliders'
        },
      ]
    },
    {
      name: 'Скидки',
      url: '/discount/product',
      icon: 'fa fa-tag',
      children: [
        {
          name: 'Товары со скидкой',
          url: '/discount/product',
          icon: 'fa fa-tag'
        },
        ...tags,
      ]
    },
    {
      name: 'Склад',
      url: '/store',
      icon: 'fa fa-barcode',
      children: [
        // {
        //   name: 'Список складов',
        //   url: '/store/store',
        //   icon: 'fa fa-barcode'
        // },
        ...storesActions
      ]
    },
    {
      name: 'Заказы',
      url: '/order',
      icon: 'fa fa-clipboard',
    },
    {
      name: 'Логистика',
      url: '/logistics',
      icon: 'fa fa-truck',
      children: [
        {
          name: 'Заказы за день',
          url: '/logistics/logistics',
          icon: 'fa fa-calendar-check-o'
        },
        {
          name: 'Распределение по курьерам',
          url: '/logistics/distribution',
          icon: 'fa fa-sort-numeric-desc'
        },
        {
          name: 'SMS тексты',
          url: '/logistics/sms-report',
          icon: 'fa fa-mobile'
        },
        {
          name: 'Менеджеры',
          url: '/logistics/manager',
          icon: 'fa fa-user-circle'
        },
        {
          name: 'Курьеры',
          url: '/logistics/delivery',
          icon: 'fa fa-drivers-license'
        },
        {
          name: 'Методы оплаты',
          url: '/logistics/payment-method',
          icon: 'fa fa-credit-card'
        },
      ]
    },
    {
      name: 'Пользователи',
      url: '/user',
      icon: 'fa fa-user',
      children: [
        {
          name: 'Список пользователей',
          url: '/user/user',
          icon: 'fa fa-users'
        },
        {
          name: 'Черный список',
          url: '/user/alert',
          icon: 'fa fa-exclamation-triangle'
        },
      ]
    },
    // {
    //   name: 'Статистика',
    //   url: '/statistic',
    //   icon: 'fa fa-line-chart',
    //   children: [
    //     {
    //       name: 'Поисковые запросы',
    //       url: '/statistic/search',
    //       icon: 'fa fa-line-chart',
    //     },
    //     {
    //       name: 'Товары',
    //       url: '/statistic/product',
    //       icon: 'fa fa-line-chart',
    //     },
    //   ]
    // },
    {
      name: 'Система',
      url: '/system',
      icon: 'fa fa-gear',
    },
    {
      name: 'Выход',
      url: '/pages/logout',
      icon: 'fa fa-sign-out',
    },
    // {
    //   name: 'Grid Tables',
    //   url: '/grid-tables',
    //   icon: 'icon-puzzle'
    // },
    // {
    //   title: true,
    //   name: 'Components',
    //   class: '',
    //   wrapper: {
    //     element: '',
    //     attributes: {}
    //   }
    // },
    // {
    //   name: 'Base',
    //   url: '/base',
    //   icon: 'icon-puzzle',
    //   children: [
    //     {
    //       name: 'Breadcrumbs',
    //       url: '/base/breadcrumbs',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Cards',
    //       url: '/base/cards',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Carousels',
    //       url: '/base/carousels',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Collapses',
    //       url: '/base/collapses',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Forms',
    //       url: '/base/forms',
    //       icon: 'icon-puzzle'
    //     },
    //     // {
    //     //   name: 'Jumbotrons',
    //     //   url: '/base/jumbotrons',
    //     //   icon: 'icon-puzzle'
    //     // },
    //     // {
    //     //   name: 'List Groups',
    //     //   url: '/base/list-groups',
    //     //   icon: 'icon-puzzle'
    //     // },
    //     {
    //       name: 'Navs',
    //       url: '/base/navs',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Navbars',
    //       url: '/base/navbars',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Paginations',
    //       url: '/base/paginations',
    //       icon: 'icon-puzzle'
    //     },
    //     // {
    //     //   name: 'Popovers',
    //     //   url: '/base/popovers',
    //     //   icon: 'icon-puzzle'
    //     // },
    //     // {
    //     //   name: 'Progress Bars',
    //     //   url: '/base/progress-bars',
    //     //   icon: 'icon-puzzle'
    //     // },
    //     {
    //       name: 'Switches',
    //       url: '/base/switches',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Tables',
    //       url: '/base/tables',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Tabs',
    //       url: '/base/tabs',
    //       icon: 'icon-puzzle'
    //     },
    //     {
    //       name: 'Tooltips',
    //       url: '/base/tooltips',
    //       icon: 'icon-puzzle'
    //     }
    //   ]
    // },
    // {
    //   name: 'Buttons',
    //   url: '/buttons',
    //   icon: 'icon-cursor',
    //   children: [
    //     {
    //       name: 'Buttons',
    //       url: '/buttons/standard-buttons',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Button Dropdowns',
    //       url: '/buttons/dropdowns',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Button Groups',
    //       url: '/buttons/button-groups',
    //       icon: 'icon-cursor'
    //     },
    //     {
    //       name: 'Brand Buttons',
    //       url: '/buttons/brand-buttons',
    //       icon: 'icon-cursor'
    //     }
    //   ]
    // },
    // {
    //   name: 'Charts',
    //   url: '/charts',
    //   icon: 'icon-pie-chart'
    // },
    // {
    //   name: 'Icons',
    //   url: '/icons',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'CoreUI Icons',
    //       url: '/icons/coreui-icons',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'info',
    //         text: 'NEW'
    //       }
    //     },
    //     {
    //       name: 'Flags',
    //       url: '/icons/flags',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Font Awesome',
    //       url: '/icons/font-awesome',
    //       icon: 'icon-star',
    //       badge: {
    //         variant: 'secondary',
    //         text: '4.7'
    //       }
    //     },
    //     {
    //       name: 'Simple Line Icons',
    //       url: '/icons/simple-line-icons',
    //       icon: 'icon-star'
    //     }
    //   ]
    // },
    // {
    //   name: 'Notifications',
    //   url: '/notifications',
    //   icon: 'icon-bell',
    //   children: [
    //     {
    //       name: 'Alerts',
    //       url: '/notifications/alerts',
    //       icon: 'icon-bell'
    //     },
    //     {
    //       name: 'Badges',
    //       url: '/notifications/badges',
    //       icon: 'icon-bell'
    //     },
    //     {
    //       name: 'Modals',
    //       url: '/notifications/modals',
    //       icon: 'icon-bell'
    //     }
    //   ]
    // },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'primary',
    //     text: 'NEW'
    //   }
    // },
    // {
    //   divider: true
    // },
    // {
    //   title: true,
    //   name: 'Extras'
    // },
    // {
    //   name: 'Pages',
    //   url: '/pages',
    //   icon: 'icon-star',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/pages/login',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Register',
    //       url: '/pages/register',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Error 404',
    //       url: '/pages/404',
    //       icon: 'icon-star'
    //     },
    //     {
    //       name: 'Error 500',
    //       url: '/pages/500',
    //       icon: 'icon-star'
    //     }
    //   ]
    // },
    // {
    //   name: 'Disabled',
    //   url: '/dashboard',
    //   icon: 'icon-ban',
    //   badge: {
    //     variant: 'secondary',
    //     text: 'NEW'
    //   },
    //   attributes: { disabled: true },
    // },
    // {
    //   name: 'Download CoreUI',
    //   url: 'http://coreui.io/vue/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    //   attributes: { target: '_blank', rel: 'noopener' }
    // },
    // {
    //   name: 'Try CoreUI PRO',
    //   url: 'http://coreui.io/pro/vue/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    //   attributes: { target: '_blank', rel: 'noopener' }
    // },
  ];
}
